import * as uuid from "uuid";

import transmissionStates from "@common/constants/ordoclic/transmissionStates";

import { getOrdoclicIframeInfos } from "../_api";

const state = {
  // Staging mode
  stagingMode: false,

  // Transmission Infos
  transmissionStep: 0,
  transmissionTotalSteps: 0,
  transmissionMessageId: null,
  transmissionState: transmissionStates.notStarted, // notStarted | inProgress | finished | failed

  // New transmission triggers values
  newTransmissionRequest: false,
  newTransmissionRequestFormType: null,
  newTransmissionRequestFormData: null,

  // Transmission Options
  autoSubmission: true,

  // Transmission result value
  transmissionResult: null,

  // Transmission error
  transmissionError: null
};

const getters = {
  transmissionInfos: state => {
    if (!state.transmissionMessageId) {
      return null;
    }
    return {
      id: state.transmissionMessageId,
      step: state.transmissionStep,
      totalSteps: state.transmissionTotalSteps,
      state: state.transmissionState
    };
  },
  transmissionResult: state => {
    return state.transmissionResult;
  },
  transmissionError: state => {
    return state.transmissionError;
  },
  triggerNewTransmission: state => {
    if (state.transmissionMessageId) {
      return false;
    }
    return state.newTransmissionRequest;
  },
  isTransmissionInProgress: state => {
    return state.transmissionState === transmissionStates.inProgress;
  }
};

const mutations = {
  INIT_TRANSMISSION_STORE: (state, stagingMode) => {
    state.stagingMode = stagingMode;

    state.transmissionStep = 0;
    state.transmissionTotalSteps = 0;
    state.transmissionMessageId = null;

    state.newTransmissionRequest = false;
    state.newTransmissionRequestFormType = null;
    state.newTransmissionRequestFormData = null;

    state.transmissionResult = null;
    state.transmissionError = null;

    state.transmissionState = transmissionStates.notStarted;
  },
  START_TRANSMISSION: (state, newTransmission) => {
    state.transmissionMessageId = newTransmission;

    state.transmissionResult = null;
    state.transmissionError = null;

    state.transmissionState = transmissionStates.inProgress;
  },
  UPDATE_TRANSMISSION_STATE: (state, { transmissionMessageId, totalSteps, step }) => {
    state.transmissionMessageId = transmissionMessageId;
    state.transmissionTotalSteps = totalSteps;
    state.transmissionStep = step;

    state.transmissionState = transmissionStates.inProgress;
  },
  UPDATE_NEW_TRANSMISSION_REQUEST: (state, { requestFormType, requestFormData, autoSubmission }) => {
    state.newTransmissionRequest = true;
    state.newTransmissionRequestFormType = requestFormType;
    state.newTransmissionRequestFormData = requestFormData;

    state.autoSubmission = autoSubmission;

    state.transmissionStep = 0;
    state.transmissionTotalSteps = 0;

    state.transmissionState = transmissionStates.notStarted;
  },
  RESET_NEW_TRANSMISSION_REQUEST: state => {
    state.newTransmissionRequest = false;
    state.newTransmissionRequestFormType = null;
    state.newTransmissionRequestFormData = null;
  },
  UPDATE_TRANSMISSION_RESULT: (state, result) => {
    state.transmissionResult = result;

    state.transmissionState = transmissionStates.finished;
  },
  UPDATE_TRANSMISSION_ERROR: (state, error) => {
    state.transmissionError = error;

    state.transmissionState = transmissionStates.failed;
  }
};

const actions = {
  getOrdoclicIframeInfos(context, { rpps, intent }) {
    return new Promise((resolve, reject) => {
      getOrdoclicIframeInfos({ rpps, intent })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  initTransmissionState(context, stagingMode = false) {
    context.commit("INIT_TRANSMISSION_STORE", stagingMode);
  },
  startNewTransmission(context, { iframe }) {
    const messageId = uuid.v4();
    console.log("[STORE-IFRAME/ACTIONS] startNewTransmission...");

    iframe.contentWindow.postMessage(
      {
        messageId,
        messageType: "amelipro.transmission",
        payload: {
          formType: context.state.newTransmissionRequestFormType,
          autoSubmission: context.state.autoSubmission,
          inputs: context.state.newTransmissionRequestFormData
        }
      },
      "*"
    );
    console.log(`amelipro-transmission ${messageId} Sent with payload : `, {
      formType: context.state.newTransmissionRequestFormType,
      autoSubmission: context.state.autoSubmission,
      inputs: context.state.newTransmissionRequestFormData
    });
  },
  requestNewTransmission(context, { requestFormType, requestFormData, autoSubmission = true }) {
    console.log("[STORE-IFRAME/ACTIONS] requestNewTransmission of type ", context.state.stagingMode ? `mock-${requestFormType}` : requestFormType);
    context.commit("UPDATE_NEW_TRANSMISSION_REQUEST", {
      requestFormType: context.state.stagingMode ? `mock-${requestFormType}` : requestFormType,
      requestFormData,
      autoSubmission
    });
  },
  resetNewTransmission(context) {
    console.log("[STORE-IFRAME/ACTIONS] resetNewTransmission");
    context.commit("RESET_NEW_TRANSMISSION_REQUEST");
  },
  updateTransmission(context, { transmissionMessageId, totalSteps, step }) {
    console.log("[STORE-IFRAME/ACTIONS] updateTransmission ", { transmissionMessageId, totalSteps, step });
    context.commit("UPDATE_TRANSMISSION_STATE", { transmissionMessageId, totalSteps, step });
  },
  endTransmission(context, { payload }) {
    console.log("[STORE-IFRAME/ACTIONS] endTransmission ", { payload });

    // Transmission success
    if (payload.form) {
      context.commit("UPDATE_TRANSMISSION_RESULT", payload.form);
    }

    // Transmission Error
    if (payload.error) {
      context.commit("UPDATE_TRANSMISSION_ERROR", payload.error);
    }
    context.dispatch("resetNewTransmission");
  }
};

export default { state, getters, mutations, actions };
