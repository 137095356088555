/**
 *  @description This function take an entity as paramaeters and extract data needed to check if entity can access a given feature. By default, retrieve all informations possble, then the helper isPilot will handle the rest
 * 
 * @param {Object} entity
 * * @param {String} [entity.channelId]
 * * @param {Array<String>} [entity.channelIds]
 * * @param {String} [entity.poleId]
 * * @param {Array<String>} [entity.poleIds]
 * * @param {String} [entity.rpps]

 * @returns {Object} Built pilot options
 */
const buildPilotOptions = entity => {
  if (!entity) {
    return;
  }

  const pilotOptions = {};

  if (entity.channelId) {
    pilotOptions.channelIds = [entity.channelId];
  }

  if (entity.channelIds) {
    pilotOptions.channelIds = entity.channelIds;
  }

  if (entity.poleId) {
    pilotOptions.poleIds = [entity.poleId];
  }

  if (entity.poleIds) {
    pilotOptions.poleIds = entity.poleIds;
  }

  if (entity.rpps) {
    pilotOptions.rpps = [entity.rpps];
  }

  return pilotOptions;
};

module.exports = buildPilotOptions;
